@media (max-width: 768px) {
    .row .mb-auto-scr {
        margin-bottom: 10px !important;
    } 
}
#lessons-purchase {
    font-family: 'Montserrat', "Helvetica", Arial, sans-serif;
    font-size: 14px;
}
@media (max-width: 768px) {
    #lessons-purchase {
        min-height: 540px;
    }
}

form#contact .form-control {
    border: none;
    height: unset;
}

#lessons-purchase .row {
    overflow: visible;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
#lessons-purchase a {
    color: #51bcda;
}
#lessons-purchase a:hover, #lessons-purchase a:focus {
    color: #2ba9cd;
    text-decoration: none; 
}
.mt-10{
    margin-top: 10px !important;
}
.card.card-blog.inst-list{
    margin-top: 20px;
}
.padded-20-cntn{
    padding: 0 20px;
}
.help-sec{
    font-weight: 600;
}
.mdl-close{
    padding: 0 !important;
    margin: 0 0 -1rem auto !important;
}
.clr-alt-red{
    color: #E7131A !important;
}
.priv-policy-link{
    color: #E7131A !important;
    font-weight: 400;
}
.contactConsent{
    font-weight: 300;
}
.top-count-ind{
    font-weight: 600;
    font-size: 14px;
}
.top-count-ind-link{
    font-weight: 600;
    color: #E7131A !important;
}
.input-req-ph{
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 0;
}
.inst-list-name{
    font-weight: bold;
    text-transform: uppercase;
    color: #E7131A !important;
    font-size: 18px;
}
.color-dark{
    color: #000 !important;
}
.section-lgray{
    background-color: #eeeff3;
}.inst-list{
    margin-top: 25px !important;
}
.shorten-bio{
    overflow: hidden;
    text-overflow: ellipsis;
}

.inst-tag{
    height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.inst-tag > p{
    font-size: 12px !important;
}
.ind-btn{
    cursor: pointer;
}
.ind-btn-tgl{
    float: none !important;
    width: 100%;
}
.ind-btn-msg{
    cursor: pointer;
    background-color: #363738;
    border-color: #363738;
}
.ind-btn-msg-tgl{
    margin-top: 6px !important;
    float: none !important;
    width: 100%;
}
.ind-icon-msg{
    color: white !important;
    font-weight: 600;
}
.contact-modal{
    margin-top: 10px !important;
    font-weight: bold;
}
.contact-modal-avatar, .book-now-avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid #F1F2F6;
}
.sticky {
    position: sticky;
    top: 20px;
    z-index: 100; /* this is optional and should be different for every project */
  }
  .modal-body.reg-modal {
    padding: 35px 100px 20px;
  }
  @media screen and (max-width: 768px) {
    .modal-body.reg-modal {
        padding: 90px 55px 20px;
    }
}
/* .reg-modal-actions{
    margin-top: 0;
    position: absolute;
    width: 100%;
    left: 15px;
    top: -26px;
} */
/* .reg-modal-actions .right-btn {
    border: none;
    height: 75px;
    background-image: url('./../img/chevronRight.svg') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-color: #FFF !important;
    width: 54px;
    background-position: center !important;
}
.reg-modal-actions .left-btn {
    background-color: none !important;
    border: none;
    height: 75px;
    background-image: url('./../img/chevronLeft.svg') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-color: #FFF !important;
    width: 54px;
    background-position: center !important;
} */

.reg-modal-form{
    height : 165px;
    overflow: auto;
}
.reg-modal-form.--instruments-select {
    display: flex;
    flex-direction: column;
    max-height: 163px;
    flex-wrap: wrap;
    margin: auto;
    max-width: 450px;

}
.dow-lbl{
    text-transform: capitalize;
}
.reg-form-start-date{
    transition: all 150ms linear;
    opacity: 0;
    visibility: hidden;
}
.content-open{
    opacity: 1 !important;
    visibility: visible !important;
}
.dropdown-item.active, .dropdown-item:active {
    color: #fff !important;
    background-color: #66615b !important;
}
.mb-auto-scr .close.rbt-close{
    margin-top: -33px;
    margin-right: 10px;
}
@media (max-width: 768px) {
    .mb-auto-scr .close.rbt-close {
        margin-right: 10px;
    }
}
.basic-instrument-ta .rbt-menu.dropdown-menu{
    max-height: 205px !important;
    border: 1px solid #dedede;
}
.basic-instrument-ta .form-control {
    color: #000;
    font-size: 16px;
    height: 46px;
    font-weight: 500;
    background: #e2e2e3;
    text-align: center;

}
@media (max-width: 768px) {
    .basic-instrument-ta .form-control {
        font-size: 14px;
    }
}
.basic-instrument-ta .form-control:focus {
    color: #000;
    font-size: 16px;
    height: 46px;
    font-weight: 500;
    text-align: center;
}
@media (max-width: 768px) {
    .basic-instrument-ta .form-control:focus {
        font-size: 14px;
    }
}
#zipcode-modal-field {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
}
#typeahead-next {
    height: 40px;
    width: 236px;
    top: 8px;
    border-radius: 4px;
    padding: 0;
    font-size: 14px;
    margin: 20px auto;
}
.rbt-input-hint {
   display: none;
}
@media (max-width: 768px) {
    #typeahead-next {
        width: 100%;
        margin: 15px auto;
    }
}
#typeahead-next:disabled {
    opacity: 1;
    background: #ea9790 !important;
    border-color: #ea9790 !important;
}
.lesson-header{
    min-height: 745px !important;
}
.info-hover{
    text-align: center;
    margin-top: -60px;
    position: relative;
    z-index: 1;
    color: white;
    display: flex;
    justify-content: center;
    font-weight: 600 !important;
}
.dp-info-hover{
    transition: transform .2s,background-color .5s;
    width: fit-content;
    cursor: pointer;
}
.dp-info-hover:hover{
    transform: scale(1.2);
}
.learn-step{
    font-weight: 600;
}
.load-btn{
    width: 100%;
    text-align: center;
}
.modal-book-now{
    max-width: 600px;
}
.modal-book-now .booking-student-section {
    margin-top: 0;
    text-align: center;
    font-weight: bold;
    margin-bottom: 19px;
    font-size: 20px;
    color: #66615b;
}
.ext-src{
    text-transform: lowercase;
}
.sess-price{
    margin-top: 0px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 33px;
}
.sess-price span {
    font-size: 30px;
    font-weight: 600;
}
.sess-price label {
    margin-bottom: 0;
}
.pad-r-0{
    padding-right: 0px;
}
.pad-r-20 {
    padding-right: 20px;
}
.dur-opt{
    margin-top: 5px;
}
.modal-schd-now{
    max-width: 900px;
    font-weight: 500;
    padding-top: 10px;
}
.know-more-subs{
    font-weight: bold;
    color: #E7131A !important;
}
/* .row-next2{
    transform: none;
    transition: transform .3s ease-out;
 overflow: hidden;
} */
.modal-dialog.modal-book-now .modal-content{
    overflow: auto;
    height: 100%;
    max-height: 100%;
}
.modal-dialog {
    max-width: 926px;
}
.fc-toolbar-schd{
    text-align: center;
}
.mob-container-ht{
    min-height: 400px;
}
.subs-ind{
    font-weight: 600;
}
.css-15g0dol-Base, .css-15g0dol-Base input, .css-15g0dol-Base button, .css-15g0dol-Base textarea, .css-15g0dol-Base select{
    font-family: 'Montserrat', sans-serif !important;
}
.ldOverlay{
    background: #e9e9e9;        
    position: absolute;   
    top: 0;                  
    right: 0;                
    bottom: 0;
    left: 0;
    opacity: 0.5;
    z-index: 10;
}
.dNone{
    display: none !important;
}
.dBlock{
    display: block !important;
}
.icLoader{
    top: 45%;
    left: 45%;
    background-color: #363738 !important;
    border-color: #363738 !important;
}
.ackSec{
    text-align: center;
}
.ackSuccess{
    color: green;
}
.ackFailure{
    color: #E7131A;
}
.gen-cnt-sec{
    justify-content: center;
    padding: 5px;
}
.gen-contact{
    padding: 10px 20px !important;
}
.c-main-nav-logo{
    margin-left: 0 !important;
}
.main-nav-bar{
    padding-top: 10px !important;
}
.main-logo{
    width: 100%;
}
.result-stat{
    width: 100%;
    margin-top: 1em;
}
.result-stat-count{
    font-size: 1.15rem;
    color: #E7131A;
}
.e165j7gc0.active{
    background-color: #E7131A !important;
    border-color: #E7131A !important;
    color: white !important;
}
.e165j7gc0{
    font-weight: 500;
}

.pref-time-pick div#preferredTimePicker {
    justify-content: center;
}
.fc-timegrid-event-condensed .fc-event-time:after {
    content: none !important;
}
.fc-timegrid-event .fc-event-time {
    white-space: normal !important;
    font-weight: 600;
}
.gen-contact-label{
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin: 7px auto;
}
.align-center{
    text-align: center !important;
}
.mt-1r{
    margin-top: 1.8rem;
}
.store-head-bold{
    font-size: 14px;
    margin: 0;
    font-weight: 700;
}
.store-info_details p{
    font-size: 12px;
    margin-bottom: 0;
    text-align: left;
    color: #585858;
    font-weight: 500;
}
.store-info-bold {
    font-weight: 700 !important;
    margin-top: 5px;
}
.no-stores-msg{
    margin: auto;
    font-weight: 600;
    text-align: center;
}
.force-hide{
    display: none !important;
}
.force-show{
    display: block !important;
}
.fw500{
    font-weight: 500 !important;
    font-size: 90% !important;
}

.pagination-div
{
    margin: 10px -3px;
    max-width: 100%;
    text-align: center;
}

.filter-font span
{
    color: #E7131A !important;
}

.filter-font a
{
    color: #E7131A !important;
}

.pagination-button
{
    margin: 0px 3px;
    text-decoration: none;
    color: #E7131A !important;
    box-sizing: border-box;
    border: 10px solid transparent;
    border-radius: 3px;
}

.pagination-button-active
{
    margin: 0px 3px;
    text-decoration: none;
    background-color: #E7131A !important;
    color: white !important;
    box-sizing: border-box;
    border: 10px solid transparent;
    border-radius: 3px;
}

.interstitial-support-menu_links a {
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}
.interstitial-support-menu_buttons a {
    font-family: "Roboto", sans-serif;
}
.interstitial-support-menu_banner {
    box-sizing: unset !important;
}
.interstitial-support-menu_hours {
    font-weight: 400;
}
.bnReqField::after{
    content: "*";
    color: #E7131A;
    margin-left:2px;
}
#callback form#contact .form-control input {
    height: unset !important;
}

@media (max-width: 768px) {
    .noResultsDivMargin {
        margin-top: 60px;
    }
}

body.fluid-width-enabled #main {
    max-width: none !important;
}