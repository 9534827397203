.modal-header {
    border-bottom: 1px solid $medium-gray;
    padding: 20px;
    text-align: center;
    display: block !important;

    &.no-border-header{
        border-bottom: 0 none !important;
        & .modal-title{
            margin-top: 20px;
            font-size: 18px;
            color: #333;

        }
    }
    button.close{
      &:focus {
        outline: none;
      }
    }
}
.modal-dialog{
    &.modal-sm,
    &.modal-register{
        margin-top: 215px;
        @media (max-width: 767px) {
            margin-top: 149px;
        }
        .modal-header{
            button.close{
                margin-top: 0;
                position: absolute;
                top: 5px;
                right: 25px;
            }
        }
        .modal-question {
            font-size: 34px;
            @media (max-width: 767px) {
                font-size: 24px;
            }
        }
    }
}

.modal-content {
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
    font-family: 'Montserrat',Helvetica,Arial,sans-serif!important;
    .modal-header{
        h6{
            margin-top: 10px;
        }
    }
}

.modal-dialog {
    padding-top: 60px;
    .row {
        overflow: visible;
    }
    .invalid-feedback {
        font-weight: 600;
        font-size: 12px;
    }
    p {
        font-weight: 500;
        color: #000;
    }
    span {
        font-weight: 500;
        color: #000;
    }
}
.modal-dialog.modal-terms {
    max-width: 550px;
    margin-top: 0;
    padding-top: 20px;
    .modal-body {
        padding: 20px;
        .terms-help {
            font-weight: 500;
            font-size: 12px;
        }
    }
}
.modal-body{
    padding: 60px 50px 20px;
    color: #000;
    @media (max-width: 767px) {
        padding: 20px 30px;
    }
    .terms-modal {
        overflow: scroll;
        max-height: 400px;
        border: 1px solid #000;
        padding: 10px;
        p {
            font-weight: 500;
            margin-bottom: 20px;
        }
    }
    &#schedule-modal-body {
        position: relative;
        .fc-v-event {
            background-color: #E7131A;
            border: 1px solid #E7131A;
            cursor: pointer;
        }
        .fc .fc-toolbar-title {
            font-weight: 600;
        }
        .fc .fc-col-header-cell-cushion {
            color: #000;
            font-weight: 600;
        }
        .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
            font-weight: 600;
        }
        .fc-timegrid-event .fc-event-time {
            font-size: 12px;
        }
        .fc .fc-button-primary {
            background-color: #E7131A;
            border-color: #E7131A;
            font-weight: 600;
        }
        .fc .fc-button-primary:disabled {
            //opacity: 1;
            background-color: #E7131A;
            border-color: #E7131A;
        }
        .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
            background-color: #B30F14;
            border-color: #B30F14;
        }
        .schedule-legend {
            position: absolute;
            height: 50px;
            width: 115px;
            top: -65px;
            right: 89px;
            background: #fff;
            z-index: 999;
            border: 1px solid #ddd;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                position: relative;
                padding-left: 25px;
                margin-bottom: 0;
                &::before {
                    content: ' ';
                    position: absolute;
                    background: #E7131A;
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    left: 3px;
                    top: 3px;
                }
            }
        }

        @media screen and (max-width: 582px) { 
            .schedule-legend {
                margin: -33px auto 10px;
                font-size: 14px;
                position: unset;
                height: 35px;
                width: 100px;
                p {
                    font-size: 13px;
                }
            }
        }

    }
}
.modal-footer {
    border-top: 1px solid $medium-gray;
    padding: 0px;

    &.no-border-footer{
        border-top: 0 none;
    }
}
.modal-footer .left-side, .modal-footer .right-side{
    display: inline-block;
    text-align: center;
    width: 49%;
}
.modal-footer .btn-link{
    padding: 20px;
    width: 100%
}
.modal-footer .divider{
    background-color: $medium-gray;
    display: inline-block;
    float: inherit;
    height: 63px;
    margin: 0px -3px;
    // position: absolute;
    width: 1px;
}

.modal-register .modal-footer{
    text-align: center;

    span{
        width: 100%;
    }
}
.modal-register .progress-steps {
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: center;
    .next-step {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #ddd;
        margin: 0 10px;
    }
    .complete-step {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #E7131A;
        margin: 0 10px;
    }
}

.modal-header:after {
     display: table;
     content: " ";
}
.modal-header:before{
    display: table;
    content: " ";
}

#reg-footer {
    background-image: url('https://studentpassimages.s3.us-west-2.amazonaws.com/Lessons_Logo.png');
    padding: 40px;
    background-repeat: no-repeat;
    background-position: 98% 0px;
    background-size: 90px;
}
